import React from "react";
import { Link } from "react-router-dom";
import "../style.css";

function NotFound() {
  return (
    <div className="min-vh-100 body">
      <div className="tengah">
        <h1>Halaman Tidak Ditemukan</h1>
        <p>
          Kembali ke <Link to={`/`}>home</Link>
        </p>
      </div>
    </div>
  );
}
export default NotFound;
