import React from "react";
import Search from "./components/Search";
import "./style.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Kategori from "./components/Kategori";
import ProdiComponent from "./components/ProdiComponent";
import AkademikComponent from "./components/AkademikComponent";
import KurikulumComponent from "./components/KurikulumComponent";
import QnaComponent from "./components/QnaComponent";
import Button from "react-bootstrap/Button";

function App() {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div className="body">
        <div className="sticky">
          <NavBar />
        </div>
        <div className="tc min-vh-100 search">
          <Search />
        </div>
        <div id="kategori">
          <Kategori />
        </div>
        <div id="prodi">
          <ProdiComponent />
        </div>
        <div id="akademik">
          <AkademikComponent />
        </div>
        <div id="kurikulum">
          <KurikulumComponent />
        </div>
        <div id="qna">
          <QnaComponent />
        </div>
        <div className="sticky-button">
        <Button onClick={scrollToTop} variant="primary">
        🔼
        </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
