import React from "react";
import "../style.css";
import logo from "../assets/logoinf.png";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";

function NavBar() {

  return (
    <div>
      <Navbar className="custom-navbar" sticky="top" data-bs-theme="dark">
        <Container>
          <div className="left-links">
            <Navbar.Brand href="/">
              <img
                alt="logo"
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
              Wiki-Inf
            </Navbar.Brand>
          </div>
          <div className="right-links">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/#kategori" >Kategori</Nav.Link>
                <Nav.Link href="/admin">Admin</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
