import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Halaman from './components/Halaman';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Write from './components/Write';
import EditHalaman from './components/EditHalaman';
import LoginForm from './components/LoginForm';
import AdminPage from './components/AdminPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
          <Route exact path="/" element={<App/>}/>
          <Route exact path="/artikel/:id" element={<Halaman/>}/>
          <Route exact path="/write" element={<Write/>}/>
          <Route exact path="/edithalaman/:id" element={<EditHalaman/>}/>
          <Route exact path="/login" element={<LoginForm/>}/>
          <Route exact path='/admin' element={<AdminPage/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
  </Router>
);
