import React from "react";
import { useNavigate } from "react-router-dom";

function KategoriCardBlue({ info }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/artikel/${info.id}`, { replace: true });
  };

  if (info && typeof info.desc === "string") {
    // Mengambil 100 karakter pertama dari description
    const truncatedTitle = info.title.slice(0, 35);
    const truncatedDescription = info.desc.slice(0, 50);

    // Tambahkan tanda elipsis jika deskripsi lebih panjang dari 100 karakter
    const displayTitle = info.title.length > 35 ? truncatedTitle + "..." : truncatedTitle;
    const displayDescription = info.desc.length > 50 ? truncatedDescription + "..." : truncatedDescription;

    const htmlContent = { __html: displayDescription };
    return (
      <div
        className="tc dib br3 pa3 ma2 grow bw2 shadow-5 custom-card-blue"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        <div>
          <h4>{displayTitle}</h4>
          <div dangerouslySetInnerHTML={htmlContent} />
        </div>
      </div>
  )}else{
    return null; 
  }  
}

export default KategoriCardBlue;
