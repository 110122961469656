import React, { useEffect, useState } from "react";
import "../style.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Container, Button, Table, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { TablePagination } from "@mui/base/TablePagination";
import DeleteConfirmation from "./DeleteConfirmation";
import "react-toastify/dist/ReactToastify.css";
axios.defaults.withCredentials = true;

function AdminPage() {
  const [name, setName] = useState("");
  const [expire, setExpire] = useState("");
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
      const decoded = jwt_decode(response.data.accessToken);
      setName(decoded.username);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwt_decode(response.data.accessToken);
        setName(decoded.username);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    // Ambil data artikel dari API (localhost:5000/artikel) saat komponen dimuat
    fetch("https://api2.wiki-inf.cloud:62542/artikel")
      .then((response) => response.json())
      .then((data) => {
        // Pemrosesan data dan penambahan nomor
        const dataWithNumber = data.map((item, index) => {
          return {
            ...item,
            nomor: index + 1,
          };
        });

        setArticles(dataWithNumber);
      });
  }, []);

  const [id, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (id) => {
    setId(id);

    setDeleteMessage(
      `Apakah anda yakin akan menghapus artikel '${
        articles.find((x) => x.id === id).title
      }'?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = (id) => {
    fetch(`https://api2.wiki-inf.cloud:62542/artikel/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Artikel berhasil dihapus!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          console.error("Gagal menghapus artikel.");
        }
      })
      .catch((error) => {
        console.error("Gagal menghapus artikel:", error);
      });
    setDisplayConfirmationModal(false);
  };

  const Logout = async () => {
    try {
      await axios.delete("https://api2.wiki-inf.cloud:62542/logout");
      toast.success("Logout berhasil, Selamat Tinggal", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {}
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articles.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="body">
      <NavBar />
      <Container>
        <div>
          <h3 style={{ marginTop: "30px" }}>
            Halo {name}, selamat datang di dashboard Admin!
          </h3>
          <Row>
            <Col xs="auto">
              <Button size="sm" variant="primary" active href="/write">
                + Tulis Artikel Baru
              </Button>{" "}
            </Col>
            <Col xs="auto" className="ml-auto">
              <Button size="sm" variant="secondary" active onClick={Logout}>
                Logout
              </Button>
            </Col>
          </Row>
          <ToastContainer />
          <br />
          <h3 style={{ textAlign: "center" }}>Daftar Artikel</h3>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>No</th>
                <th style={{ textAlign: "center" }}>Judul</th>
                <th style={{ textAlign: "center" }}>Kategori</th>
                <th style={{ textAlign: "center" }}>Tanggal Publikasi</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? articles.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : articles
              ).map((article) => (
                <tr key={article.id}>
                  <td style={{ textAlign: "center" }}>{article.nomor}</td>
                  <td style={{ maxWidth: "450px" }}>{article.title}</td>
                  <td style={{ textAlign: "center" }}>{article.cat}</td>
                  <td style={{ textAlign: "center" }}>
                    {article.createdAt.split("T")[0]}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      style={{ marginRight: "5px" }}
                      size="sm"
                      href={`/artikel/${article.id}`}
                    >
                      Lihat
                    </Button>
                    <Button
                      style={{ marginRight: "5px" }}
                      size="sm"
                      href={`/edithalaman/${article.id}`}
                    >
                      Edit
                    </Button>
                    <Button
                      Button
                      size="sm"
                      variant="danger"
                      articleId={article.id}
                      onClick={() => showDeleteModal(article.id)}
                    >
                      Hapus
                    </Button>
                  </td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr style={{ height: 41 * emptyRows }}>
                  <td colSpan={5} aria-hidden />
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={articles.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  slotProps={{
                    select: {
                      "aria-label": "rows per page",
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </tr>
            </tfoot>
          </Table>
        </div>
      </Container>
      <Footer />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        id={id}
        message={deleteMessage}
      />
    </div>
  );
}

export default AdminPage;
