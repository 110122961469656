import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function Kategori(props) {
  const customButtonStyle = {
    padding: "20px 10px", // Sesuaikan sesuai kebutuhan Anda
    fontSize: "30px", // Sesuaikan sesuai kebutuhan Anda
    backgroundColor: "#004b84",
  };

  return (
    <div>
      <Container className="kat-container">
        <Row>
          <Col className="text-center">
            <h1 className="judul">Berdasarkan Kategori</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-grid gap-4">
              <Button
                href="/#prodi"
                variant="primary"
                style={customButtonStyle}
              >
                PROGRAM STUDI
              </Button>
              <Button href="/#akademik" variant="primary" style={customButtonStyle}>
                AKADEMIK
              </Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-4" style={{ marginLeft: "10px" }}>
              <Button href="/#kurikulum" variant="primary" style={customButtonStyle}>
                KURIKULUM
              </Button>
              <Button href="/#qna" variant="primary" style={customButtonStyle}>
                Q&A
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Kategori;
