import React from 'react'
import '../style.css'

function Footer() {
  return (
    <div className='footer'>
        <p>Zuhrul Ikfa x Wikipedia Informatika</p>
        <p>Universitas Nahdlatul Ulama Al Ghazali Cilacap</p>
        <p>Hak Cipta © 2023</p>
    </div>
  )
}
export default Footer;