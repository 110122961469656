import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import DeleteButton from "./DeleteButton";
import { Button, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteConfirmation from "./DeleteConfirmation";
axios.defaults.withCredentials = true;

function EditHalaman() {
  const [expire, setExpire] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
      const decoded = jwt_decode(response.data.accessToken);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { id } = useParams();

  const articleId = parseInt(id, 10);

  const [article, setArticle] = useState({
    title: "",
    cat: "",
    desc: "",
  });

  useEffect(() => {
    fetch(`https://api2.wiki-inf.cloud:62542/artikel/${articleId}`)
      .then((response) => response.json())
      .then((data) => {
        setArticle(data);
      })
      .catch((error) => {
        console.error("Gagal mengambil data artikel:", error);
      });
  }, [articleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle({ ...article, [name]: value });
  };

  const handleDescChange = (value) => {
    setArticle({ ...article, desc: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://api2.wiki-inf.cloud:62542/artikel/${articleId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(article),
        }
      );
      if (response.ok) {
        toast.success("Artikel berhasil diupdate!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate(`/admin`);
        }, 2000);
      } else {
        // Handle the error case
        console.error("Gagal menambahkan artikel.");
      }
    } catch (error) {
      console.error("Gagal menambahkan artikel:", error);
    }
  };

  const Batal = () => {
    navigate(`/admin`);
  };

  const [iD, setId] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const showDeleteModal = (iD) => {
    setId(iD);

    setDeleteMessage(
      `Apakah anda yakin akan menghapus artikel '${article.title}'?`
    );

    setDisplayConfirmationModal(true);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const submitDelete = (iD) => {
    fetch(`https://api2.wiki-inf.cloud:62542/artikel/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Artikel berhasil dihapus!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });

          setTimeout(() => {
            navigate(`/admin`);
          }, 1500);
        } else {
          console.error("Gagal menghapus artikel.");
        }
      })
      .catch((error) => {
        console.error("Gagal menghapus artikel:", error);
      });
    setDisplayConfirmationModal(false);
  };

  return (
    <div className="body">
      <NavBar />
      <div className="halaman">
        <div className="container">
          <div className="main-content">
            <h2>Edit Artikel</h2>
            <div className="write">
              <div className="inputs">
                Judul:
                <input
                  name="title"
                  id="title"
                  className="form-control mt-1"
                  value={article.title}
                  onChange={handleChange}
                />
                Kategori:
                <select
                  name="cat"
                  id="kategori"
                  className="form-control mt-1"
                  value={article.cat}
                  onChange={handleChange}
                >
                  <option selected>Pilih Kategori</option>
                  <option value="prodi">Prodi</option>
                  <option value="kurikulum">Kurikulum</option>
                  <option value="akademik">Akademik</option>
                  <option value="qna">QnA</option>
                </select>
                {/* <input
                  name="cat"
                  id="cat"
                  className="form-control mt-1"
                  value={article.cat}
                  onChange={handleChange}
                /> */}
              </div>
              <div className="quillContainer">
                Deskripsi:
                <ReactQuill
                  name="desc"
                  theme="snow"
                  value={article.desc}
                  onChange={handleDescChange}
                  className="quill"
                />
              </div>
            </div>
          </div>
          <div className="sidebar">
            <Row style={{ marginBottom: "10px" }}>
              <Col sm={8} style={{ paddingRight: "0px" }}>
                <Button style={{ width: "100%" }} onClick={handleSubmit}>
                  Simpan Perubahan
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  style={{ width: "100%" }}
                  variant="secondary"
                  onClick={Batal}
                >
                  Batal
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <Button
                  style={{ width: "100%", padding: "10px" }}
                  size="sm"
                  variant="danger"
                  articleId={article.id}
                  onClick={() => showDeleteModal(article.id)}
                >
                  Hapus
                </Button>
              </Col>
            </Row>
            <ToastContainer />
          </div>
        </div>
      </div>
      <Footer />
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        id={id}
        message={deleteMessage}
      />
    </div>
  );
}

export default EditHalaman;
