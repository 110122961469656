import React from 'react';
import Card from './Card';

function SearchList({ filteredInfos }) {
  const filtered = filteredInfos.map(info =>  <Card key={info.id} info={info} />); 
  return (
    <div className='card-container'>
      {filtered}
    </div>
  );
}

export default SearchList;