import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
axios.defaults.withCredentials = true;

function Write() {
  const [expire, setExpire] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
      const decoded = jwt_decode(response.data.accessToken);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        navigate("/login");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get("https://api2.wiki-inf.cloud:62542/token");
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const [article, setArticle] = useState({
    title: "",
    cat: "",
    desc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle({ ...article, [name]: value });
  };

  const handleDescChange = (value) => {
    setArticle({ ...article, desc: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isTitleValid) {
      toast.error("Judul wajib diisi!", {
        position: toast.POSITION.TOP_CENTER, // Atur posisi toast sesuai kebutuhan Anda
      });
      return; // Jangan lanjutkan aksi jika judul tidak valid
    }

    if (article.title.trim() === "") {
      setIsTitleValid(false);
    } else {
      setIsTitleValid(true);
      try {
        const response = await fetch("https://api2.wiki-inf.cloud:62542/artikel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(article),
        });
        if (response.ok) {
          console.log("Artikel berhasil ditambahkan!");
          toast.success("Artikel berhasil ditambahkan!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setButtonDisabled(true);
          setTimeout(() => {
            window.location.reload();
            setButtonDisabled(false);
          }, 1500);
        } else {
          // Handle the error case
          console.error("Gagal menambahkan artikel.");
        }
      } catch (error) {
        console.error("Gagal menambahkan artikel:", error);
      }
    }
  };

  return (
    <div>
      <NavBar />
      <div className="halaman" style={{ marginTop: "30px" }}>
        <Container>
          <div className="main-content write">
            <div className="inputs">
              Judul:
              <input
                type="text"
                name="title"
                id="title"
                className="form-control mt-1"
                placeholder="Judul"
                value={article.title}
                onChange={handleChange}
              />
              Kategori:
              <select
                name="cat"
                id="kategori"
                className="form-control mt-1"
                value={article.cat}
                onChange={handleChange}
              >
                <option selected>Pilih Kategori</option>
                <option value="prodi">Prodi</option>
                <option value="kurikulum">Kurikulum</option>
                <option value="akademik">Akademik</option>
                <option value="qna">QnA</option>
              </select>
            </div>
            <div className="quillContainer">
              Deskripsi:
              <ReactQuill
                name="desc"
                theme="snow"
                value={article.desc}
                onChange={handleDescChange}
                className="quill"
              />
            </div>
          </div>
          <div className="sidebar">
            <Button onClick={handleSubmit} disabled={isButtonDisabled}>
              Kirim Artikel
            </Button>
            <ToastContainer />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Write;
